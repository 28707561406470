import { createSelector } from 'reselect';

const getUsers = (state) => state.TypingUser.Users;
const getConversationId = (state, props) => props.ConversationId;
const getUserId = (state, props) => props.UserId;

export const selectTypingUsers = createSelector(
  [getUsers, getConversationId, getUserId],
  (users, conversationId, userId) =>
    users.filter((x) => x.id === conversationId && x.userId !== userId)
);
