import React, { Fragment, useEffect } from 'react';
import { unmountComponentAtNode } from 'react-dom';
import $ from "jquery";

const SuccessAlert = (props) => {

    useEffect(() => {
        setTimeout(function () {
            if (props.alertType === "success") {
                $(".banner-success-master").addClass("showmsgTop");
            } else if (props.alertType === "warning") {
                $(".banner-warning-master").addClass("showmsgTop");
            } else if (props.alertType === "fail") {
                $(".banner-fail-master").addClass("showmsgTop");
            }
        }, 500);
        setTimeout(function () {
            if (props.alertType === "success") {
                $(".banner-success-master").removeClass("showmsgTop");
            } else if (props.alertType === "warning") {
                $(".banner-warning-master").removeClass("showmsgTop");
            } else if (props.alertType === "fail") {
                $(".banner-fail-master").removeClass("showmsgTop");
            }
        }, props.displaytime);
        setTimeout(function () {
            unmountComponentAtNode(document.getElementById("OpenDialog1"));
        }, props.displaytime + 200);
    }, []);

    const getIcon = () => {
        if (props.alertType === "success") {
            return "";
        } else if (props.alertType === "warning") {
            return "";
        } else if (props.alertType === "fail") {
            return "";
        }
    };

    return (
        <Fragment>
            <div className={`banner-${props.alertType}-master`}>
                <div className="icon-banner font-awesome-normal">{getIcon()}</div>
                <div className="text-block-28">{props.strMessage}</div>
            </div>
        </Fragment>
    );
};

export default SuccessAlert;
