import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '65b5ff5d-6507-4631-b6ea-6dc2c34691b4',
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
const appInsightTrackEvent = (objdata) => {
  try {
      appInsights.trackEvent({
          name: objdata.Name,
          properties: {
              businessId: objdata.Bid,
              userid: objdata.Userid,              
              functionName: objdata.Fname,
              description: objdata.Description,
              parameters: objdata.Parameters
          }
      });
  } catch (e) {
  }
  
}
export { reactPlugin, appInsights, appInsightTrackEvent };