import React from "react";
import './loader.scss'
import { createPortal } from "react-dom";

const Loader = (props) => {
  if (props.isgrid) {
    return (
      <div className="ingridLoading"><div className="loaderNew"><div className="loader-imgNew"></div></div></div>
    );
  }
  else {
    return (

      createPortal(
      <div  className="loaderNew block-edit"><div className="loader-imgNew"></div></div>,
      document.body
      )
    );
  }

  
};
export default Loader;