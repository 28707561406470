import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Nav, Row, Tab } from 'react-bootstrap'
import { AsyncAjaxGet } from '../component/Common/AsyncAjaxServiceV2';
import { useDispatch } from 'react-redux';
import useGenerateToken from '../component/Hooks/useGenerateToken';
import moment from 'moment';
import { utils } from '../utils/Utils';

const VagaroDrive = (props) => {
    const { merchantId, show, setShow, onUploadClickFromVagaroDrive } = props;
    const VagaroAPIEnum = {
        VagarodrivesDetails: `${merchantId}/VagaroConnect/vagarodrives/`,
    }

    const [vagaroDriveData, setVagaroDriveData] = useState([]);
    const [navLinkChange, setNavLinkChange] = useState(1);
    const dispatch = useDispatch();

    const [selectedFilesPosition, setSelectedFilesPosition] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isStenciled, setStenciled] = useState(true);
    const [pageIndex, setPageIndex] = useState(1);
    const [restrictPaging, setRestrictPaging] = useState(false);
    const driveRef = useRef(null);

    const handleClose = () => setShow(false);


    useEffect(() => {
        if (restrictPaging === false) {
            setStenciled(true);
            GetVagarodrivesDetails();
        }
    }, [navLinkChange, pageIndex]);

    const GetVagarodrivesDetails = async () => {
        const accesstoken = await useGenerateToken(dispatch);
        await AsyncAjaxGet(
            VagaroAPIEnum.VagarodrivesDetails + "detail?FileActionType=" + navLinkChange + "&PageIndex=" + pageIndex + "&PageSize=10",
            accesstoken,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        if (data.data.data.length > 0) {
                            if (data.data.data.length < 10) {
                                setRestrictPaging(true);                                
                            }
                            var tempData = data.data.data.map((x) => {
                                return { ...x, "isSelected": false }
                            })
                            if (vagaroDriveData.length > 0) {
                                tempData = vagaroDriveData.concat(tempData);
                            }
                            setStenciled(false);
                            setVagaroDriveData(tempData);
                        } else {
                            setRestrictPaging(true);
                            setStenciled(false);
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Vagaro Drive Details",
                                "",
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    }
                    else {                        
                        setStenciled(false);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Vagaro Drive Details",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {                    
                    setStenciled(false);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Vagaro Drive Details",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                setStenciled(false);
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Vagaro Drive Details",
                    "",
                    data
                );
            },
            false
        );
    }

    const getFileByFileType = (data) => {
        if (data.fileType === "pdf") {
            //PDF
            return (<i class="fas fa-file-pdf"></i>)
        }
        else if (data.fileType === "xlsx" || data.fileType === "xls") {
            //Excel
            return (<i class="fas fa-file-excel"></i>)
        }
        else if (data.fileType === "mp4") {
            //Mp4
            return (<i class="fas fa-video"></i>)
        }
        else if (data.fileType === "txt") {
            //Text
            return (<i class="fas fa-file-alt"></i>)

        }
        // else if (data.fileType === "mp3") {
        //     //Mp3 //other
        //     return (< i class="fas fa-images" ></i >)
        // }
        else if (data.fileType === "jpg" || data.fileType === "png" || data.fileType === "jpeg" || data.fileType === "gif") {
            //Image
            return (<img src={data.fileUrl} className="card-img-top" alt="" />)
        } else {
            return (< i class="fas fa-images" ></i >)
        }
    }
    const onSelectFilesFromGrid = (selecteditem) => {
        var tempItems = [];
        for (let index = 0; index < vagaroDriveData.length; index++) {
            const element = vagaroDriveData[index];
            if (element.fileDetailId === selecteditem.fileDetailId) {
                if (element.isSelected === true) {
                    element.isSelected = false;
                } else {
                    element.isSelected = true;
                }
            }
            tempItems.push(element);
        }

        const updatedFiles = [...selectedFiles];
        const fileIndex = updatedFiles.findIndex((selectedFile) => selectedFile.fileDetailId === selecteditem.fileDetailId);
        var selectedItemPosition = [];
        if (fileIndex === -1) {
            // File is not selected, add it to the list
            updatedFiles.push(selecteditem);
        } else {
            // File is selected, remove it from the list
            updatedFiles.splice(fileIndex, 1);
        }
        updatedFiles.map((x, index) => {
            selectedItemPosition.push({ "fileId": x.fileDetailId, "position": index + 1 });
        })
        setSelectedFiles(updatedFiles)
        setSelectedFilesPosition(selectedItemPosition);
        setVagaroDriveData(tempItems);
    }

    const OnClickOfUpload = () => {
        handleClose();
        onUploadClickFromVagaroDrive(selectedFiles)
    }

    const OnChangeOfNavType = (navType) => {
        if (navType === navLinkChange) {
            return;
        }
        else {
            setSelectedFiles([]);
            setSelectedFilesPosition([]);
            setVagaroDriveData([]);
            setRestrictPaging(false);
            setNavLinkChange(navType)
            setPageIndex(1);
        }
    }

    return (
        <Modal show={show} onHide={handleClose} className={isStenciled ? "stenciled" : " "} size="lg" centered id="vDriveModal">
            <Modal.Header>
                <Modal.Title>
                    <span>
                        Upload Vagaro Drive Files
                    </span>

                    <a onClick={handleClose}>
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.39453 8.48047C9.77539 8.83203 9.77539 9.44727 9.39453 9.79883C9.21875 9.97461 8.98438 10.0625 8.75 10.0625C8.48633 10.0625 8.25195 9.97461 8.07617 9.79883L5 6.72266L1.89453 9.79883C1.71875 9.97461 1.48438 10.0625 1.25 10.0625C0.986328 10.0625 0.751953 9.97461 0.576172 9.79883C0.195312 9.44727 0.195312 8.83203 0.576172 8.48047L3.65234 5.375L0.576172 2.29883C0.195312 1.94727 0.195312 1.33203 0.576172 0.980469C0.927734 0.599609 1.54297 0.599609 1.89453 0.980469L5 4.05664L8.07617 0.980469C8.42773 0.599609 9.04297 0.599609 9.39453 0.980469C9.77539 1.33203 9.77539 1.94727 9.39453 2.29883L6.31836 5.4043L9.39453 8.48047Z" fill="#333333" />
                        </svg>
                    </a>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                    <Row className="drive-wrap">
                        <Col className='tabNavWrap'>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="1" onClick={() => { OnChangeOfNavType(1) }}>Appointment Files</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="2" onClick={() => { OnChangeOfNavType(2) }}>Customer Files</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="3" onClick={() => { OnChangeOfNavType(3) }}>Forms Files</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col className='modal-body-wrap' ref={driveRef} onScroll={(e) => { parseInt(driveRef.current.scrollHeight - (driveRef.current.scrollTop + driveRef.current.clientHeight)) < 1 && e.target.scrollHeight > e.target.clientHeight && isStenciled === false && restrictPaging === false && setPageIndex(pageIndex + 1) }}>
                            {/* <Tab.Content>
                                <Tab.Pane eventKey="1"> */}
                            {isStenciled === true && vagaroDriveData.length === 0 ?
                                <>
                                    <Row lg={4} md={3} sm={2}>
                                        {[1, 2, 3, 4, 5, 6, 7, 8].map((x) => {
                                            return (
                                                <Col>
                                                    <div className="card stenciledBlockForDrive">
                                                        <div className='card-imgWrap'>
                                                            <div className="drive-file-type">

                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <h5 className="card-title"></h5>
                                                            <p className="card-text"></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </>
                                :
                                <>
                                    {vagaroDriveData.length > 0 ?
                                        <>
                                            <h3 className='tabTitle'>{navLinkChange === 1 ? "Appointment Files" : navLinkChange === 2 ? "Customer Files" : "Forms Files"}</h3>
                                            <Row lg={4} md={3} sm={2}>
                                                {vagaroDriveData && vagaroDriveData.length > 0 && vagaroDriveData.map((data, index) => {
                                                    return (
                                                        <Col>
                                                            <div className={data.isSelected === true ? "card selected" : "card"} onClick={() => { onSelectFilesFromGrid(data) }}>
                                                                <div className='card-imgWrap'>
                                                                    {data.isSelected === true &&
                                                                        <span className='selectedCount'>
                                                                            {selectedFilesPosition.filter((p) => p.fileId === data.fileDetailId).length > 0 && selectedFilesPosition.filter((p) => p.fileId === data.fileDetailId)[0].position}
                                                                        </span>
                                                                    }
                                                                    <div className="drive-file-type">
                                                                        {getFileByFileType(data)}
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <h5 className="card-title">{data.fileName}</h5>
                                                                    <p className="card-text">{data.strUploadDate !== undefined && data.strUploadDate !== null && data.strUploadDate}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </>
                                        :
                                        <div className='d-flex justify-content-center align-items-center h-100'>No record found</div>
                                    }
                                </>
                            }
                            {/* </Tab.Pane>
                                <Tab.Pane eventKey="2">Customer Files</Tab.Pane>
                                <Tab.Pane eventKey="3">Forms Files</Tab.Pane>
                            </Tab.Content> */}
                        </Col>
                    </Row>
                </Tab.Container>

            </Modal.Body >
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {vagaroDriveData.length > 0 &&
                    <Button variant="primary" onClick={OnClickOfUpload}>
                        Upload
                    </Button>
                }
            </Modal.Footer>
        </Modal >
    );
}

export default VagaroDrive