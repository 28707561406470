import React from 'react';
import { utils } from '../utils/Utils';

const VCard = ({ reactFirstName, reactLastName, reactBusinessName, reactProfileImage, reactCdnUrl }) => {
    return (
        <div className="v-card">
            <div className='vcCard-title'>
                <div className='vcCard-title_name'>{reactFirstName}&nbsp;{reactLastName}</div>
                <div className='vcCard-business_name'>{reactBusinessName}</div>
            </div>
            <div className="avatar avatar-sm" data-avatar="1">
                <div className="avatar-group-img-wrap">
                    {reactProfileImage !== undefined && reactProfileImage !== null && reactProfileImage !== "" ?
                        <img alt='avtar' src={reactCdnUrl + 'Original/' + reactProfileImage} className='avatar-group-member' />
                        :
                        <div> {utils.setLetter(reactFirstName || "", reactLastName || "")}</div>
                    }
                </div>
            </div>
        </div>
    );
}

export default VCard;
