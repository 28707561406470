import React, { Fragment, useEffect, useRef } from 'react';
import { useState } from 'react';
import { EmployeeDetailsData, EmployeeGroupDetailsData } from './Binding/StenciedData';
import { DateFormatType, HandleHistory, isAndroidIOSTabIpad, utils } from '../utils/Utils';
import { AsyncAjaxGet, AsyncAjaxPut } from '../component/Common/AsyncAjaxServiceV2';
import { CommonMethods, MassageTypeEnum, MobileCallBackAction, SelectionControlTypeEnum } from './Binding/ChatBoatEnums';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import $ from "jquery";
import MultiGroupSelectEmployee from '../component/Controls/MultiGroupSelectEmployee';
import { useChatContext } from './Store/ChatProvider';
import moment from 'moment';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import SuccessAlert from '../component/Common/SuccessAlert';
import { Groupicon, Usericon } from './CommonComponent/Icons';

export const EmployeeDetails = (props) => {
    const { setConversetionChatSelection, access_token, merchantId, isNewChat, allserviceprovider, convertionData, employeeData, employeeNewChat, SendMessage, LoginUserIdHash, isLeftOption, ModifyEmployee, FullName, setConvertionData, isFromConnectApp, isAndroidIOSMobile, isViewChanging, setTempConvertionData, setChatAreaShow, CheckLoginUserIsRemovedFromChat, ManageEmployeeViewRights, ManageEmployeeModifyRights, isFromAppResumeCallBack, isIOSMobile, UpsertChannel, employeeGroupDetails, MerchantChannelId, CountryID } = props
    const { chatSelectionID, chatSelection, AddCommonMethod, connection, CommonMethod } = useChatContext();
    const VagaroAPIEnum = {
        GetEmployeeProfile: `${merchantId}/VagaroConnect/employees/detail?EmployeeId=${chatSelectionID}`,
        GetGroupDetail: `connect/${merchantId}/conversations/group/members?`,
        UpdateGroupName: `connect/${merchantId}/conversation/group`,
        AddEmployee: `connect/${merchantId}/employee/group/`,
        Employeeinbusiness: `connect/${merchantId}/employeeinbusiness`,
    };
    const [isStenciled, setStenciled] = useState(true);
    const [showMoreLocation, setShowMoreLocation] = useState(false);
    const [addMembers, setAddMembers] = useState(false);
    const [isRemoveMember, setRemoveMember] = useState(false);
    const [tempRemoveMemberId, setTempRemoveMemberId] = useState("");
    const [tempRemoveMemberobject, setTempRemoveMemberobject] = useState("");
    const [GroupName, setGroupName] = useState("");
    const [tempGroupName, setTempGroupName] = useState("");
    const [isUpdateName, setIsUpdateName] = useState(false);
    const [employeeProfileDetails, setEmployeeProfileDetails] = useState(EmployeeDetailsData);
    const [groupDetails, setGroupDetails] = useState(EmployeeGroupDetailsData);
    const [ServiceproviderasperMultilocation, setServiceproviderasperMultilocation] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isGroupNameChanged, setIsGroupNameChange] = useState(false);


    const [groupAdmin, setGroupAdmin] = useState("");
    const GroupNameInput = useRef()
    const isFromAddRemove = useRef(false);
    let orientationQuery = window.matchMedia('(orientation: portrait)');


    const SendMessageRequest = (type, newValue) => {
        const RequestData = {};
        switch (type) {
            case MassageTypeEnum.ChageGroupName:
                RequestData.conversationId = chatSelection === undefined || chatSelection === null || chatSelection === "" ? null : chatSelection.ConversationId;
                RequestData.SenderId = props.LoginUserID;
                RequestData.fullname = "";
                RequestData.message = `${FullName} changed the group name to ${newValue}.`;
                RequestData.MemberIds = null;
                RequestData.status = 0;
                RequestData.isnote = false;
                RequestData.attachment = [];
                RequestData.isGroupUpdateMessage = true;
                RequestData.SentByHash = LoginUserIdHash;
                RequestData.IsRemove = false;
                break;
            case MassageTypeEnum.AddMamber:
                var NewMemberNameList = [];
                for (let index = 0; index < allserviceprovider.length; index++) {
                    const element = allserviceprovider[index];
                    if (newValue.filter((m) => m === element.value).length > 0) {
                        NewMemberNameList.push(element.label);
                    }
                }
                RequestData.conversationId = chatSelection === undefined || chatSelection === null || chatSelection === "" ? null : chatSelection.ConversationId;
                RequestData.SenderId = props.LoginUserID;
                RequestData.fullname = "";
                RequestData.message = NewMemberNameList.length > 0 ? `${FullName} added ${NewMemberNameList.join(' ,')} to the chat.` : `${FullName} added someone to the chat.`;
                //RequestData.message = NewMemberNameList.length === 1 ? `${FullName} added ${NewMemberNameList[0]} to the chat.` : NewMemberNameList.length === 2 ? `${FullName} added ${NewMemberNameList[0]} and ${NewMemberNameList[1]}  to the chat.` : `${FullName} added ${NewMemberNameList[0]} and ${NewMemberNameList.length - 1} others to the chat.`;
                RequestData.MemberIds = null;
                RequestData.status = 0;
                RequestData.isnote = false;
                RequestData.attachment = [];
                RequestData.isGroupUpdateMessage = true;
                RequestData.SentByHash = LoginUserIdHash;
                RequestData.IsRemove = false;
                break;
            case MassageTypeEnum.RemoveMamber:
                RequestData.conversationId = chatSelection === undefined || chatSelection === null || chatSelection === "" ? null : chatSelection.ConversationId;
                RequestData.SenderId = props.LoginUserID;
                RequestData.fullname = "";
                RequestData.message = tempRemoveMemberobject.memberIdHash === LoginUserIdHash ? `${tempRemoveMemberobject.fullName} left the chat.` : ` ${FullName} removed  ${tempRemoveMemberobject.fullName} from the chat.`
                RequestData.MemberIds = null;
                RequestData.status = 0;
                RequestData.isnote = false;
                RequestData.attachment = [];
                RequestData.isGroupUpdateMessage = true;
                RequestData.SentByHash = LoginUserIdHash;
                RequestData.IsRemove = tempRemoveMemberobject.memberIdHash === LoginUserIdHash ? true : false;
                break;

            default:
                break;
        }
        return RequestData;
    }
    const SentMessage = (type, newValue) => {
        let Data = SendMessageRequest(type, newValue);
        const newData = convertionData.map(item => {
            if (item.conversationId === chatSelection.AllData.conversationId) {
                return { ...item, name: GroupName };
            }
            return item;
        });
        SendMessage(Data, '', newData, type === MassageTypeEnum.RemoveMamber ? false : true);
    }


    const Request = (val) => {
        const RequestData = {};
        RequestData.groupName = val !== undefined ? val : GroupName;
        RequestData.conversationId = chatSelectionID;
        // RequestData.isGroupRenamed = val !== undefined ? false : true;
        RequestData.isGroupRenamed = isFromAddRemove.current === true ? false : true;
        RequestData.message = isFromAddRemove.current ? "" : `${FullName} changed the group name to ${RequestData.groupName}.`;
        RequestData.ReceiverIdHash = LoginUserIdHash;
        RequestData.ChanelId = MerchantChannelId.toString();
        RequestData.senderId = props.LoginUserID;
        
        return RequestData;
    }
    const GetEmployeesProfile = async () => {
        setStenciled(true);
        await AsyncAjaxGet(
            VagaroAPIEnum.GetEmployeeProfile,
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        setEmployeeProfileDetails(data.data.data);
                        setStenciled(false);
                        // utils.SetHeight("fromEmployee");
                        if (data.data.data?.cell) {
                            chatSelection.AllData.cell = data.data.data?.cell;
                        }
                        if (data.data.data?.photo) {
                            chatSelection.AllData.photo = data.data.data.photo;
                        }
                        if (data.data.data?.cdnUrl) {
                            chatSelection.AllData.cdnUrl = data.data.data.cdnUrl;
                        }
                    }
                    else {
                        setStenciled(false);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Employee Profile",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    setStenciled(false);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Employee Profile",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                setStenciled(false);
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Employee Profile",
                    "",
                    data
                );
            }
        );
    }

    const GroupNameChange = (data) => {
        return data.members.map((name) => name.fullName.split(" ").length > 0 ? name.fullName.split(" ")[0] : "").join(", ");
    }

    const CheckEmployeeinbusiness = async (employeeData) => {
        await AsyncAjaxGet(
            VagaroAPIEnum.Employeeinbusiness + `?employeeId=${employeeData?.memberId}`,
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        if (data.data.data) {
                            window.parent.postMessage('{"eventType": "openEmployeeProfile","Data": "' + employeeData.memberId + '"}', '*');
                        } else {
                            return ReactDOM.render(
                                <Fragment>
                                    <SuccessAlert
                                        alertType={"warning"}
                                        strMessage={"You can not edit any details of employee of multilocation business."}
                                        displaytime={3000}
                                    />
                                </Fragment>,
                                document.getElementById("OpenDialog1")
                            );
                        }
                    }
                    else {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Employee Profile",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Employee Profile",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Employee Profile",
                    "",
                    data
                );
            }
            , true
        );
    }

    const GetGroupDetail = async (UpdateFromSignar, isFromAddRemoveUser = false) => {
        setStenciled(true);
        const URl = () => {
            var url = VagaroAPIEnum.GetGroupDetail;
            if (isFromConnectApp) {
                url += `ConversationId=${chatSelection.ConversationId}`;
            } else {
                url += `ConversationId=${chatSelectionID}`;
            }
            return url;
        }
        await AsyncAjaxGet(
            URl(),
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        var groupName = "";
                        setIsGroupNameChange(data.data.data.isGroupRenamed);
                        isFromAddRemove.current = false;
                        if (data.data.data.isGroupRenamed === true) {
                            isFromAddRemove.current = false;
                        } else if (isFromAddRemoveUser === true && data.data.data.isGroupRenamed === false) {
                            isFromAddRemove.current = true;
                            // groupName = GroupNameChange(data.data.data);
                            // setGroupName(groupName);
                        }
                        if (data.data.data.groupName && data.data.data.groupName !== "" && data.data.data.isGroupRenamed === true) {
                            setGroupName(data.data.data.groupName);
                        } else {
                            groupName = GroupNameChange(data.data.data);
                            setGroupName(groupName);
                        }
                        setGroupDetails(data.data.data);
                        const filteredData = data.data.data.members.filter(obj => obj.memberIdHash === data.data.data.groupAdminIdHash);
                        if (filteredData.length > 0) {
                            setGroupAdmin(data.data.data.groupAdminIdHash);
                        }
                        else {
                            setGroupAdmin("");
                        }
                        setStenciled(false);
                        if (UpdateFromSignar) {
                            var chatselectionTemp = chatSelection.AllData;
                            var member = data.data.data.members.map((member) => chatselectionTemp.members.find((v) => v.memberIdHash === member.memberIdHash) ? chatselectionTemp.members.find((v) => v.memberIdHash === member.memberIdHash) : {
                                "memberId": member.memberId,
                                "memberIdHash": member.memberIdHash,
                                "cdnUrl": member.cdnUrl,
                                "firstName": member.fullName.split(" ")[0] || "",
                                "lastName": member.fullName.split(" ")[1] || "",
                                "photo": member.photo || "",
                                "isMemberRemovedFromGroup": false
                            });
                            const updatedData = convertionData.map(item => item.conversationId === data.data.data.conversationId ? { ...item, name: data.data.data.isGroupRenamed ? data.data.data.groupName : groupName, members: member } : item);
                            chatselectionTemp.name = data.data.data.isGroupRenamed ? data.data.data.groupName : groupName;
                            chatselectionTemp.members = member;
                            setConversetionChatSelection(chatselectionTemp, true);
                            setConvertionData(updatedData);
                            // setTempConvertionData(updatedData);                            
                        }else{
                            var chatselectionTemp = chatSelection.AllData;
                            var updatedMembers = data.data.data.members.map((member) => {
                                return {
                                    "memberId": member.memberId,
                                    "memberIdHash": member.memberIdHash,
                                    "cdnUrl": member.cdnUrl,
                                    "firstName":utils.getFirstName(member.fullName),
                                    "lastName":utils.getLastName(member.fullName) || "",
                                    "photo": member.photo || "",
                                    "cell": member.cell,
                                    "userType": member.userType
                                };
                            });
                            chatSelection.AllData.Allmember = updatedMembers;                            
                        }
                        // utils.SetHeight("fromEmployee");
                    }
                    else {
                        setStenciled(false);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Get Group Details",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    setStenciled(false);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Get Group Details",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                setStenciled(false);
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Get Group Details",
                    "",
                    data
                );
            },
            true
        );
    }
    const UpdateGroupName = async (groupName) => {

        setLoader(true);
        await AsyncAjaxPut(
            VagaroAPIEnum.UpdateGroupName,
            access_token,
            Request(groupName),
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        var chatselectionTemp = chatSelection.AllData;
                        chatselectionTemp.name = groupName !== undefined ? groupName : GroupName;
                        setConversetionChatSelection(chatselectionTemp, true);
                        if (isFromAddRemove.current === false) {
                            let updatedData = convertionData.map(d =>
                                d.conversationId === chatSelectionID
                                    ? { ...d, name: chatselectionTemp.name }
                                    : d
                            );
                            setConvertionData(updatedData);
                            SentMessage(MassageTypeEnum.ChageGroupName, chatselectionTemp.name);
                            // const sentRequestData = {};
                            // sentRequestData.conversationId = chatSelectionID;
                            // sentRequestData.action = MassageTypeEnum.ChageGroupName;
                            // sentRequestData.fullname = GroupName;
                            // if (connection.state === 'Connected') {
                            //     connection.invoke('SendGroup', window.ReactBusinessId + "", sentRequestData);
                            // }
                        }
                        isFromAddRemove.current = false;
                        setLoader(false);
                    }
                    else {
                        setLoader(false);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Update Group Name",
                            Request(groupName),
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    setLoader(false);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Update Group Name",
                        Request(groupName),
                        error
                    );
                }
            },
            function OnError(data) {
                setLoader(false);
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Update Group Name",
                    Request(groupName),
                    data
                );
            },
            true
        );
    }
    const ChatwithEmployee = (d) => {
        var employee = convertionData.filter((val) => (val.customerIdHash === d.memberIdHash && val.isGroupChat === false));
        if (isAndroidIOSMobile) {
            // CommonMethod.MobileCallBackForBack();
            // employeeGroupDetails.current = chatSelection.AllData;
            if (employee.length === 0) {
                setTimeout(() => { setChatAreaShow(false) }, 200);
            }
        }
        if (employee.length > 0) {
            if (isFromConnectApp && isAndroidIOSMobile) {
                CommonMethod.Actionclick("rightMobileHeaderBack");
            }
            setConversetionChatSelection(employee[0], true)
        } else {
            // var defultValues = (employeeData || []).find(childval => childval.memberIdHash === d.memberIdHash);
            employeeNewChat(d.memberIdHash || "");
        }
    }

    const GroupNameFuction = (data) => {
        return data.map((name) => name.firstName).join(", ");
    }

    const AddGroupEmployee = async (employees, remove = false, newSelectedValues = [], isChecked) => {
        if (remove === false && newSelectedValues.length === 0) {
            return ReactDOM.render(
                <Fragment>
                    <SuccessAlert
                        alertType={"warning"}
                        strMessage={`Select at least one Teammate`}
                        displaytime={2000}
                    />
                </Fragment>,
                document.getElementById("OpenDialog1")
            );
            //utils.ShowAlert("Alert", "Please Select Any Employee", "Close");
        }
        if (isAndroidIOSMobile || isAndroidIOSTabIpad()) {
            CommonMethod.Actionclick("div-select2-Close-selection");
            AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("rightMobileHeaderBack"); })
        }
        if ("Employee" === isLeftOption && ModifyEmployee === false) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={"You Don't Have Rights"}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     document.getElementById("OpenDialog1")
            // );
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        setAddMembers(false);
        setLoader(true);
        const RequestData = {};
        RequestData.membersIds = employees;
        RequestData.conversationId = chatSelectionID;
        RequestData.IsIncludeAllChat = isChecked;
        RequestData.receiverIdHash = LoginUserIdHash;
        RequestData.ChanelId = MerchantChannelId.toString();
        if (remove) {
            RequestData.message = tempRemoveMemberobject.memberIdHash === LoginUserIdHash ? `${tempRemoveMemberobject.fullName} left the chat.` : ` ${FullName} removed  ${tempRemoveMemberobject.fullName} from the chat.`
            RequestData.membersIdsHash = tempRemoveMemberobject.memberIdHash;
        } else {
            var NewMemberNameList = [];
            for (let index = 0; index < allserviceprovider.length; index++) {
                const element = allserviceprovider[index];
                if (newSelectedValues.filter((m) => m === element.value).length > 0) {
                    NewMemberNameList.push(element.label);
                }
            }
            RequestData.message = NewMemberNameList.length > 0 ? `${FullName} added ${NewMemberNameList.join(' ,')} to the chat.` : `${FullName} added someone to the chat.`;
            RequestData.membersIdsHash = employees.split(",").map(val => allserviceprovider.find(arr => arr.value === val).UserIdHash)?.join(",");
        }

        await AsyncAjaxPut(
            VagaroAPIEnum.AddEmployee + (remove ? "remove" : "add"),
            access_token,
            RequestData,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        const sentRequestData = {};
                        setAddMembers(false);
                        setRemoveMember(false);
                        if (!remove) {
                            var addedMemberList = [];
                            allserviceprovider.map((x) => {
                                if (newSelectedValues.filter((y) => y === x.value).length > 0) {
                                    var providerCdnUrl = x.profile !== "" ? (x.profile.split('Original/').length > 1 ? x.profile.split('Original/')[0] : "") : "";
                                    var providerPhoto = x.profile !== "" ? (x.profile.split('Original/').length > 1 ? x.profile.split('Original/')[1] : "") : "";
                                    addedMemberList.push({
                                        cdnUrl: providerCdnUrl || "",
                                        firstName: x.label.split(" ")[0] || "",
                                        lastName: x.label.split(" ")[1] || "",
                                        memberId: x.value,
                                        memberIdHash: x.UserIdHash,
                                        photo: providerPhoto || "",
                                        isMemberRemovedFromGroup: false
                                    })
                                }
                            })
                            for (let index = 0; index < convertionData.length; index++) {
                                const element = convertionData[index];
                                if (element.conversationId === chatSelection.ConversationId) {
                                    element.members.concat(addedMemberList);
                                }
                            }

                            const updatedData = convertionData.map(item => item.conversationId === RequestData.conversationId ? { ...item, name: isGroupNameChanged ? item.name : GroupNameFuction(item.members.concat(addedMemberList)), members: item.members.concat(addedMemberList) } : item);
                            setConvertionData(updatedData);
                            setTempConvertionData(updatedData);
                            if (RequestData.conversationId === chatSelection.ConversationId) {
                                chatSelection.AllData.members = chatSelection.AllData.members.concat(addedMemberList);
                            }

                        }
                        else {
                            //for update group icon when remove -- Start
                            let element = "";
                            const filterdata = convertionData?.filter((d) => d.conversationId === RequestData.conversationId);
                            if (filterdata.length > 0) {
                                element = filterdata[0].members.map(item => item.memberIdHash === tempRemoveMemberobject.memberIdHash ? { ...item, isMemberRemovedFromGroup: true } : item);
                                console.log(element);
                                filterdata[0].members = element;
                                chatSelection.AllData.members = element;
                            }
                            const updatedData = convertionData.map(item => item.conversationId === RequestData.conversationId ? filterdata[0] : item);
                            setConvertionData(updatedData);
                            setTempConvertionData(updatedData);
                            if (RequestData.conversationId === chatSelection.ConversationId) {
                                chatSelection.AllData.members = element;
                            }
                            //for update group icon when remove --End
                            if (LoginUserIdHash === RequestData.membersIdsHash) {
                                const updatedData = convertionData.map(item => item.conversationId === RequestData.conversationId ? { ...item, isLoggedInUserRemovedFromGroup: true } : item);
                                if (RequestData.conversationId === chatSelection.ConversationId && !isNewChat) {
                                    chatSelection.AllData.isLoggedInUserRemovedFromGroup = true;
                                }
                                setConvertionData(updatedData);
                                setTempConvertionData(updatedData);
                            }
                        }

                        SentMessage(remove ? MassageTypeEnum.RemoveMamber : MassageTypeEnum.AddMamber, newSelectedValues)
                        // sentRequestData.conversationId = chatSelectionID;
                        // sentRequestData.action = remove ? MassageTypeEnum.RemoveMamber : MassageTypeEnum.AddMamber;
                        // if (remove) {
                        //     sentRequestData.MemberIds = tempRemoveMemberobject.memberIdHash;
                        // } else {
                        //     sentRequestData.MemberIds = RequestData.membersIds.split(",").map(val => allserviceprovider.find(arr => arr.value === val).UserIdHash)?.join(",");
                        // }
                        // if (connection.state === 'Connected') {
                        //     connection.invoke('SendGroup', window.ReactBusinessId + "", sentRequestData);
                        // }

                        if (remove && LoginUserIdHash === RequestData.membersIdsHash) {
                            //do nothing when member left the chat
                        } else {
                            GetGroupDetail(false, true);
                        }
                        setLoader(false);
                    }
                    else {
                        setLoader(false);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Add Remove Employee",
                            RequestData,
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    setLoader(false);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Add Remove Employee",
                        RequestData,
                        error
                    );
                }
            },
            function OnError(data) {
                setLoader(false);
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Add Remove Employee",
                    RequestData,
                    data
                );
            },
            true
        );
    }
    const ActivityStatus = ({ status }) => {
        const Class = () => {
            switch ((status || "").toLowerCase()) {
                case "in appointment":
                    return "active-status status-yellow";
                case "after hours":
                    return "active-status status-gray";
                case "in meeting":
                    return "active-status status-yellow";
                case "on pto":
                    return "active-status status-red";
                case "available":
                    return "active-status status-green";
                default:
                    return "active-status status-red";
            }

        }
        return (<div className="block-status">
            <div className={Class()}>
                {status}
            </div>
        </div>)

    }
    const AddMemberPopup = () => {
        const [serviceprovider, setserviceprovider] = useState({ optionSelected: null });
        const [serviceproviderName, setserviceproviderName] = useState("");
        const [newSelectedValues, setnewSelectedValues] = useState([]);
        const [isChecked, setIsChecked] = useState(false);
        const defultValues = (groupDetails?.members || []).map(val => (employeeData || []).find(childval => childval.memberIdHash === val.memberIdHash)).map(val => val?.memberId).filter(val => val !== undefined);
        const DefultList = allserviceprovider.filter(val => defultValues.includes(val.value));
        DefultList.forEach(element => {
            if (!defultValues.includes(element.RoleID)) {
                defultValues.push(element.RoleID);
            }
        });
        CommonMethod.HandleInputFocus();
        return (
            <>
                {loader && <div className="loaderNew"><div className="loader-imgNew"></div></div>}
                <Modal show={addMembers} size="lg " className="add-member-dialogue mobile-fullscreen-pop addMemberModal">
                    <Modal.Header closeButton onHide={() => {
                        setAddMembers(false);
                        CommonMethod.RemoveElementbyClass("GroupddEmployee");
                        CommonMethod.RemoveClassToBody("addTeamMateModalOpen");
                        if (isAndroidIOSMobile) {
                            CommonMethod.Actionclick("div-select2-Close-selection");
                            AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("rightMobileHeaderBack"); })
                            HandleHistory("back");
                        }
                    }}>
                        <Modal.Title><span>Add Teammates</span>
                            {/* <a href={false} onClick={() => setAddMembers(false)}>
                                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.39453 8.48047C9.77539 8.83203 9.77539 9.44727 9.39453 9.79883C9.21875 9.97461 8.98438 10.0625 8.75 10.0625C8.48633 10.0625 8.25195 9.97461 8.07617 9.79883L5 6.72266L1.89453 9.79883C1.71875 9.97461 1.48438 10.0625 1.25 10.0625C0.986328 10.0625 0.751953 9.97461 0.576172 9.79883C0.195312 9.44727 0.195312 8.83203 0.576172 8.48047L3.65234 5.375L0.576172 2.29883C0.195312 1.94727 0.195312 1.33203 0.576172 0.980469C0.927734 0.599609 1.54297 0.599609 1.89453 0.980469L5 4.05664L8.07617 0.980469C8.42773 0.599609 9.04297 0.599609 9.39453 0.980469C9.77539 1.33203 9.77539 1.94727 9.39453 2.29883L6.31836 5.4043L9.39453 8.48047Z" fill="#333333" />
                                </svg>
                            </a> */}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='m-content-hide'>
                            <p>Select teammates to add to this chat.</p>
                            {/* <input id="txtFirstNameCust" name="txtFirstNameCust" type="text" class="ctrlDisable v-commoninput" placeholder="Search Employee" /> */}
                            <div className="select-employee-wrap">
                                <MultiGroupSelectEmployee
                                    className=""
                                    classNamePrefix="vg-select"
                                    aria-label="Default select example"
                                    options={ServiceproviderasperMultilocation}
                                    onChange={(serviceprovider) => {
                                        setserviceprovider({ optionSelected: serviceprovider });
                                    }}
                                    onChangeSpName={(SpName) => {
                                        setserviceproviderName(SpName);
                                    }}
                                    // value={props.providervalue}
                                    isMulti
                                    ControlID="GroupddEmployee"
                                    searchInputPlaceholder={"Search"}
                                    defultSelection={defultValues}
                                    newSelectedValues={(value) => { setnewSelectedValues(value) }}
                                    placeholder="Select an teammates"
                                    isandroidiospro={isAndroidIOSMobile}
                                    isFromConnectApp={isFromConnectApp}
                                    isNewChat={isNewChat}
                                />
                            </div>
                        </div>
                        <div className="chat-history-box">
                            <label className="check-label">Include all chat history.
                                <input type="checkbox" checked={isChecked} onClick={(e) => { setIsChecked(!isChecked); }} />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button id="addMemberCloseBtn" variant="secondary" onClick={() => {
                            setAddMembers(false);
                            CommonMethod.RemoveElementbyClass("GroupddEmployee");
                            if (isAndroidIOSMobile) {
                                CommonMethod.Actionclick("div-select2-Close-selection");
                                CommonMethod.Actionclick("div-select2-Close-selection");
                                AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("rightMobileHeaderBack"); })
                            }
                        }}>Cancel</Button>
                        <Button variant="btn btn-primary vcgradientbtn vcprimary-btn" onClick={() => { AddGroupEmployee(serviceprovider.optionSelected, false, newSelectedValues, isChecked) }}>{isAndroidIOSTabIpad() ? 'Add Teammates' : isAndroidIOSMobile ? 'Add Teammates' : 'Add'}</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
    const RemoveMemberConformationPopup = () => {
        return (
            <Modal show={isRemoveMember} onHide={() => { setRemoveMember(false); AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("rightMobileHeaderBack"); }) }} size="lg modal-mb-half" centered className="add-member-dialogue">
                <Modal.Header closeButton>
                    <Modal.Title><span>Remove Teammate</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to remove {tempRemoveMemberobject.fullName} from group?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button id='removeMemberBack' variant="secondary vcgradientbtn vcsecondary-btn" onClick={() => { setTempRemoveMemberId(""); setRemoveMember(false); setTempRemoveMemberobject(""); AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("rightMobileHeaderBack"); }) }}>
                        <div className="vcsecondaryInner">Cancel</div>
                    </Button>
                    <Button variant="primary vcgradientbtn vcprimary-btn" onClick={() => { AddGroupEmployee(tempRemoveMemberId, true); setRemoveMember(false); AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("rightMobileHeaderBack"); }) }}>Remove</Button>
                </Modal.Footer>
            </Modal>
        )
    }
    const EditGroupName = (Open = false, Done = false, value) => {
        if (("Employee" === isLeftOption && ModifyEmployee === false)) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={"You Don't Have Rights"}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     window.parent.document.getElementById("OpenDialog1")
            // );
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else if (!Open && Done) {
            let Value = value.trim();
            if (Value !== null && Value !== "" && Value !== undefined) {
                setTempGroupName(GroupName);
                setGroupName(Value);
                if (GroupName !== value && Value !== "") {
                    UpdateGroupName(Value);
                }
            }
        }
        setIsUpdateName(Open)
        setTimeout(() => {
            GroupNameInput.current.focus();
        }, 200);
    }
    const getAvatar = (value) => {
        var FilterData = (chatSelection.AllData.members || []).filter((val) => val.memberIdHash === value.memberIdHash);
        if (FilterData.length > 0 && FilterData[0].photo) {
            return <img src={FilterData[0].cdnUrl + 'Original/' + FilterData[0].photo} alt="" />
        } else {
            return <div> {utils.setLetter(value.fullName.split(" ")[0] || "", value.fullName.split(" ")[1] || "")}</div>
        }
    }

    const UnderDevelopment = () => {
        return ReactDOM.render(
            <Fragment>
                <SuccessAlert
                    alertType={"warning"}
                    strMessage={"Under development."}
                    displaytime={2000}
                />
            </Fragment>,
            document.getElementById("OpenDialog1")
        );
        /*window.OpenEmployeeProfilePopupFromVagaroConnect && window.OpenEmployeeProfilePopupFromVagaroConnect(val.memberId)*/
    }
    const Containt = () => {
        switch (chatSelection.IsFromGroup) {
            case true:
                return (
                    <>
                        <div id='employee_GroupDetails' className="rightside-wrapper employee-detail-page">
                            <div className='emp-back-arrow'><i class="fa-solid fa-chevrons-left"></i></div>
                            <div className='right-mobileHeaderDetailBlock'>
                                <a href={false} id='rightMobileHeaderBack' onClick={() => {
                                    if (isFromConnectApp && isAndroidIOSMobile) {
                                        UpsertChannel(chatSelection.AllData.customerId, chatSelection?.AllData?.customerIdHash, isFromConnectApp ? true : false);
                                        CommonMethod.RemoveClass(".vConnectMainWrap.chatActive", "detailsActive");
                                        AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("messagesAreaheaderback"); })
                                        HandleHistory("back");
                                    }
                                }} className='right-mobileHeader-back' >
                                    <i class="fa-solid fa-angle-left"></i>
                                </a>
                                <div className={isAndroidIOSMobile === true && CheckLoginUserIsRemovedFromChat(chatSelection.AllData) === false ? 'right-mobileHeader-detail m-right-none' : 'right-mobileHeader-detail'}>
                                    Details
                                </div>
                                {isAndroidIOSMobile === true && CheckLoginUserIsRemovedFromChat(chatSelection.AllData) === false &&
                                    <div id="divAddMember" className='right-icon' onClick={() => { CommonMethod.AddClassToBody("addTeamMateModalOpen"); setAddMembers(true); HandleHistory("push", "EmployeeDropdown"); AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("addMemberCloseBtn"); }); }}>
                                        <i class="fa-regular fa-user-plus"></i>
                                    </div>
                                }
                            </div>
                            <div className='member-list-wrapper'>

                                <div className="profile-wrapper text-center services-list-wrap">
                                    <div className="personal-info">
                                        {isFromConnectApp === true && CheckLoginUserIsRemovedFromChat(chatSelection.AllData) === false && isAndroidIOSTabIpad() && orientationQuery.matches === false &&
                                            <div id="divAddMember" className='right-icon' onClick={() => { CommonMethod.AddClassToBody("addTeamMateModalOpen"); setAddMembers(true); HandleHistory("push", "EmployeeDropdown"); AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("addMemberCloseBtn"); }); }}>
                                                <i class="fa-regular fa-user-plus"></i>
                                            </div>
                                        }
                                        <div className="profile-image">
                                            {chatSelection.AllData.isGroupChat === true &&
                                                <Groupicon data={chatSelection.AllData.members.filter((x) => x.isMemberRemovedFromGroup === false)} />

                                            }
                                        </div>
                                        <div className="name-wrap">
                                            <div style={{ display: isUpdateName ? "flex" : "none" }} className='groupNameWrap'>
                                                <input id="groupNameInput" ref={GroupNameInput} type="text" defaultValue={GroupName} maxLength={36} onKeyDown={(event) => event.key === 'Enter' && EditGroupName(false, true, $("#groupNameInput").val())} />
                                                {CheckLoginUserIsRemovedFromChat(chatSelection.AllData) === false &&
                                                    <div id="conformGroupName" className='right-icon' onClick={() => EditGroupName(false, true, $("#groupNameInput").val())} >
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.61914 10.8359C7.29688 11.1582 6.79883 11.1582 6.47656 10.8359L4.60156 8.96094C4.2793 8.63867 4.2793 8.14062 4.60156 7.81836C4.92383 7.49609 5.42188 7.49609 5.74414 7.81836L7.0625 9.10742L10.2266 5.94336C10.5488 5.62109 11.0469 5.62109 11.3691 5.94336C11.6914 6.26562 11.6914 6.76367 11.3691 7.08594L7.61914 10.8359ZM15.5 8.375C15.5 12.5352 12.1309 15.875 8 15.875C3.83984 15.875 0.5 12.5352 0.5 8.375C0.5 4.24414 3.83984 0.875 8 0.875C12.1309 0.875 15.5 4.24414 15.5 8.375ZM8 2.28125C4.63086 2.28125 1.90625 5.03516 1.90625 8.375C1.90625 11.7441 4.63086 14.4688 8 14.4688C11.3398 14.4688 14.0938 11.7441 14.0938 8.375C14.0938 5.03516 11.3398 2.28125 8 2.28125Z" fill="#6D6E6F" />
                                                        </svg>
                                                    </div>
                                                }
                                            </div>
                                            <div style={{ display: isUpdateName ? "none" : "flex" }} className='groupNameWrap'>
                                                <h3 style={{ textTransform: "capitalize" }}>{GroupName}</h3>
                                                {CheckLoginUserIsRemovedFromChat(chatSelection.AllData) === false &&
                                                    <div id="editGroupName" className='right-icon' onClick={() => EditGroupName(true)} >
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.55469 11.4512C1.67188 11.041 1.90625 10.6602 2.19922 10.3672L11.1055 1.46094C11.8379 0.728516 13.0391 0.728516 13.7715 1.46094L14.9141 2.60352C15.002 2.69141 15.0898 2.80859 15.1484 2.89648C15.6465 3.62891 15.5586 4.625 14.9141 5.26953L6.00781 14.1758C5.97852 14.2051 5.91992 14.2344 5.89062 14.293C5.59766 14.5273 5.27539 14.7031 4.92383 14.8203L1.37891 15.8457C1.14453 15.9336 0.880859 15.875 0.705078 15.6699C0.5 15.4941 0.441406 15.2305 0.5 14.9961L1.55469 11.4512ZM2.22852 14.1465L4.51367 13.4727C4.57227 13.4434 4.66016 13.4141 4.71875 13.3848L3.66406 13.1797C3.42969 13.1211 3.22461 12.9453 3.19531 12.7109L2.99023 11.6562C2.96094 11.7148 2.93164 11.8027 2.90234 11.8613L2.22852 14.1465ZM5.77344 12.418L11.7207 6.4707L9.875 4.6543L3.95703 10.6016L4.25 12.0957L5.77344 12.418Z" fill="#333333" />
                                                        </svg>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div className="bottom-content-wrap">
                                        <div style={{ display: "none" }} className='team-heading'>{isFromConnectApp ? groupDetails?.members.length + " Teammates" : "Teammates"}</div>
                                        <div className='members-list-wrap'>
                                            <div className='member-header' >
                                                <h3>Teammates</h3>
                                                {isAndroidIOSMobile === false && CheckLoginUserIsRemovedFromChat(chatSelection.AllData) === false &&
                                                    <div className='right-icon' onClick={() => setAddMembers(true)}>
                                                        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.1875 8.375C5.10742 8.375 3.4375 6.70508 3.4375 4.625C3.4375 2.57422 5.10742 0.875 7.1875 0.875C9.23828 0.875 10.9375 2.57422 10.9375 4.625C10.9375 6.70508 9.23828 8.375 7.1875 8.375ZM7.1875 2.28125C5.86914 2.28125 4.84375 3.33594 4.84375 4.625C4.84375 5.94336 5.86914 6.96875 7.1875 6.96875C8.47656 6.96875 9.53125 5.94336 9.53125 4.625C9.53125 3.33594 8.47656 2.28125 7.1875 2.28125ZM8.65234 9.78125C11.4648 9.78125 13.75 12.0664 13.75 14.8789C13.75 15.4355 13.2812 15.875 12.7246 15.875H1.62109C1.06445 15.875 0.625 15.4355 0.625 14.8789C0.625 12.0664 2.88086 9.78125 5.69336 9.78125H8.65234ZM2.03125 14.4688H12.3145C12.0801 12.6523 10.5566 11.1875 8.65234 11.1875H5.69336C3.78906 11.1875 2.23633 12.6523 2.03125 14.4688ZM18.6719 6.73438C19.0527 6.73438 19.375 7.05664 19.375 7.4375C19.375 7.84766 19.0527 8.14062 18.6719 8.14062H17.2656V9.54688C17.2656 9.95703 16.9434 10.25 16.5625 10.25C16.1523 10.25 15.8594 9.95703 15.8594 9.54688V8.14062H14.4531C14.043 8.14062 13.75 7.84766 13.75 7.4375C13.75 7.05664 14.043 6.73438 14.4531 6.73438H15.8594V5.32812C15.8594 4.94727 16.1523 4.625 16.5625 4.625C16.9434 4.625 17.2656 4.94727 17.2656 5.32812V6.73438H18.6719Z" fill="#333333" />
                                                        </svg>
                                                    </div>
                                                }
                                            </div>
                                            {(groupDetails?.members || []).map((val) =>
                                                <div class="chatList-person">
                                                    {/* <a href={false} class="chatList-person_dotMenu">


                                                    </a> */}
                                                    <Dropdown className="chatList-person_dotMenu mobile-contextmenu" onToggle={(toggle, element) => { if (isAndroidIOSMobile) { toggle ? AddCommonMethod("MobileCallBackForBack", () => { element.originalEvent.target.click(); }) : AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("rightMobileHeaderBack"); }) } }} >
                                                        <Dropdown.Toggle variant="">
                                                            <i className='fa-solid fa-ellipsis-vertical'></i>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <div className='team-employee-name'>
                                                                <div class="chatList-person_left">
                                                                    <div class="avatar avatar-sm" data-avatar="1">
                                                                        {val.photo ?
                                                                            <div class="avatar-group-img-wrap">
                                                                                <img src={val.cdnUrl + 'Original/' + val.photo} alt="" />
                                                                            </div>
                                                                            :
                                                                            <div class="avatar-group-img-wrap">
                                                                                <div> {utils.setLetter(val.fullName.split(" ")[0] || "", val.fullName.split(" ")[1] || "")}</div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div class="chatList-person_right">
                                                                    <div class="chatList-person_name">{val.fullName}</div>
                                                                </div>
                                                            </div>
                                                            {(val.memberIdHash !== LoginUserIdHash) &&
                                                                <Dropdown.Item onClick={(e) => { if (!isFromConnectApp) { CommonMethod.MobileCallBackForBack(); } HandleHistory("back"); ChatwithEmployee(val) }} title='Message'><i class="fa-regular fa-comment"></i>Message</Dropdown.Item>
                                                            }
                                                            {!isFromConnectApp &&
                                                                <Dropdown.Item onClick={(e) => {
                                                                    // if (isFromConnectApp) {
                                                                    //     // utils.UnderDevelopment();
                                                                    //     utils.CallBackGivenToMobile(MobileCallBackAction.EmployeeProfile, { "employeeId": val.memberId })
                                                                    // } else {
                                                                    //     window.parent.postMessage('{"eventType": "openEmployeeProfile","Data": "' + val.memberId + '"}', '*');
                                                                    // }
                                                                    CheckEmployeeinbusiness(val);

                                                                }} title='View Profile'><i class="fa-regular fa-user"></i>View Profile</Dropdown.Item>
                                                            }
                                                            {(groupAdmin === LoginUserIdHash || val.memberIdHash === LoginUserIdHash) && groupDetails?.members.length > 2 &&
                                                                <Dropdown.Item onClick={(e) => { setTempRemoveMemberobject(val); setTempRemoveMemberId(val.memberId); setRemoveMember(true); setTimeout(() => { AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("removeMemberBack"); }) }, 100); }} title='Remove from Chat' className='remove-chat-red'><i class="fa-regular fa-trash"></i>Remove from Group</Dropdown.Item>
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                    <div class="chatList-person_left">
                                                        <div class="avatar avatar-sm" data-avatar="1">
                                                            {val.photo ?
                                                                <div class="avatar-group-img-wrap">
                                                                    <img src={val.cdnUrl + 'Original/' + val.photo} alt="" />
                                                                </div>
                                                                :
                                                                <div class="avatar-group-img-wrap">
                                                                    <div> {utils.setLetter(val.fullName.split(" ")[0] || "", val.fullName.split(" ")[1] || "")}</div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="chatList-person_right">
                                                        <div class="chatList-person_name">{val.fullName}</div>
                                                        <div class="chatList-person_number" onClick={() => isFromConnectApp && utils.CallBackGivenToMobile(MobileCallBackAction.PhoneNumber, val.cell)}>{(ManageEmployeeViewRights || ManageEmployeeModifyRights) && val.cell}</div>
                                                        {val.isActive === false && <div class="chatList-person_name">inactive</div>}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* <a href={false} className='float-end'>
                                        <i class="fa-solid fa-chevron-right"></i>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <AddMemberPopup />
                        <RemoveMemberConformationPopup />
                    </>
                );
            case false:
                return (<div className="rightside-wrapper employee-detail" >
                    <div className='emp-back-arrow'><i class="fa-solid fa-chevrons-left"></i></div>
                    <div className='right-mobileHeaderDetailBlock'>
                        <a href={false} id='rightMobileHeaderBack' onClick={() => { isFromConnectApp && UpsertChannel(chatSelection.AllData.customerId, chatSelection?.AllData?.customerIdHash, isFromConnectApp ? true : false); CommonMethod.RemoveClass(".vConnectMainWrap.chatActive", "detailsActive"); AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("messagesAreaheaderback"); }); HandleHistory("back"); }} className='right-mobileHeader-back' >
                            <i class="fa-regular fa-angle-left"></i>
                        </a>
                        <div className='right-mobileHeader-detail'>
                            Details
                        </div>
                    </div>
                    <div className="personal-info">
                        <div className="profile-wrapper text-center services-list-wrap" onClick={() => {
                            if (isFromConnectApp && employeeProfileDetails?.userId) {
                                if (employeeProfileDetails?.employeeInBusiness === false) {
                                    return ReactDOM.render(
                                        <Fragment>
                                            <SuccessAlert
                                                alertType={"warning"}
                                                strMessage={"You can not edit any details of employee of multilocation business."}
                                                displaytime={3000}
                                            />
                                        </Fragment>,
                                        document.getElementById("OpenDialog1")
                                    );
                                } else {
                                    utils.CallBackGivenToMobile(MobileCallBackAction.EmployeeProfile, { "employeeId": employeeProfileDetails.userId })
                                }
                            }
                        }}>
                            <div className="profile-image" onClick={() => {
                                if (!isFromConnectApp) {
                                    if (employeeProfileDetails?.employeeInBusiness === false) {
                                        return ReactDOM.render(
                                            <Fragment>
                                                <SuccessAlert
                                                    alertType={"warning"}
                                                    strMessage={"You can not edit any details of employee of multilocation business."}
                                                    displaytime={3000}
                                                />
                                            </Fragment>,
                                            document.getElementById("OpenDialog1")
                                        );
                                    } else {
                                        window.parent.postMessage('{"eventType": "openEmployeeProfile","Data": "' + employeeProfileDetails.encEmployeeId + '"}', '*');
                                    }
                                }
                            }}>
                                <Usericon Mid URL={employeeProfileDetails.cdnUrl} Image={employeeProfileDetails.photo} FirstName={employeeProfileDetails.firstName} LastName={employeeProfileDetails.lastName} />
                            </div>


                            <div className="name-wrap">
                                <h3>{employeeProfileDetails.firstName + " " + employeeProfileDetails.lastName}</h3>
                            </div>
                            <ActivityStatus status={employeeProfileDetails.employeeStatus} />
                        </div>

                    </div>
                    {isFromConnectApp && isAndroidIOSMobile && !isAndroidIOSTabIpad() &&
                        <div className='messageBtnwrap'>
                        <button className='btn btn-message' onClick={() => { ChatwithEmployee({ memberIdHash: employeeProfileDetails.userIdHash })}}>
                                <span className='messageBtnWrapForMobile'>
                                    <i class="fa-regular fa-comment-dots"></i> Message
                                </span>
                            </button>
                        </div>
                    }
                    <div className="bottom-content-wrap">
                        <div className="customer-detail-wrap services-list-wrap">
                            <div className="contact-detail">
                                <div className="contact-wrap">
                                    {employeeProfileDetails.cell !== undefined && employeeProfileDetails.cell !== null && employeeProfileDetails.cell !== "" &&
                                        <a href={false} className={`${(ManageEmployeeViewRights || ManageEmployeeModifyRights) ? 'contact-wrap-callWrap' : 'contact-wrap-callWrap norights'}`} onClick={() => { isFromConnectApp && utils.CallBackGivenToMobile(MobileCallBackAction.PhoneNumber, employeeProfileDetails.cell) }}>
                                            <div className="contact-icon">
                                                <i class="fa-regular fa-phone-flip"></i>
                                            </div>
                                            <span>{(ManageEmployeeViewRights || ManageEmployeeModifyRights) ? employeeProfileDetails.cell : "No Access"}</span>
                                        </a>
                                    }
                                    {employeeProfileDetails.emailId !== undefined && employeeProfileDetails.emailId !== null && employeeProfileDetails.emailId !== "" &&
                                        <a href={`mailto: ${employeeProfileDetails.emailId}`} className={`${(ManageEmployeeViewRights || ManageEmployeeModifyRights) ? 'contact-wrap-emailWrap' : 'contact-wrap-emailWrap norights'}`} onClick={() => { isFromConnectApp && utils.CallBackGivenToMobile(employeeProfileDetails.emailId, {}, "Email") }}>
                                            <div className="contact-icon">
                                                <i className="far fa-envelope"></i>
                                            </div>
                                            <span className='contact-wrap-emailText'>{(ManageEmployeeViewRights || ManageEmployeeModifyRights) ? employeeProfileDetails.emailId : "No Access"}</span>
                                        </a>
                                    }

                                </div>
                            </div>
                        </div>
                        {employeeProfileDetails?.businessLocations.length > 1 &&
                            <div className="businessLocationWrap">
                                <div>
                                    <div className="contact-wrap">
                                        <a href={false}>
                                            <div className="contact-icon">
                                                {/*<i class="fa-regular fa-clock"></i>*/}
                                                <i class="far fa-clock"></i>
                                            </div>
                                            <span>{ utils.countryDateFormat(moment(),DateFormatType.SmallDateWithTime,CountryID)}</span>
                                        </a>
                                    </div>

                                    <div className="businessLocationBlock">
                                        <div className="iconWrap">
                                            <i class="fa-regular fa-store"></i>
                                        </div>
                                        <div className="locationList">
                                            {(employeeProfileDetails?.businessLocations || []).map((val, index, arr) => {
                                                if (arr.length > 8 && !showMoreLocation) {
                                                    if (index === 8) {
                                                        return (<><div className="location">{val.businessName}</div> <a href={false} style={{ cursor: "pointer" }} className="locationToogle" onClick={() => setShowMoreLocation(true)}>+ {arr.length - 9 === 1 ? arr.length - 9 + " more location" : arr.length - 9 > 0 && arr.length - 9 + " more locations"}</a></>)
                                                    } else if (index < 8) {
                                                        return (<div className="location">{val.businessName}</div>)
                                                    }
                                                } else {
                                                    return (<div className="location">{val.businessName}</div>)
                                                }
                                            }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div >);
            default:
                break;
        }
    }
    const RenderRightSidePanel = (UpdateFromSignar = false) => {

        if (chatSelectionID !== "" && chatSelection.Type === SelectionControlTypeEnum.Employee) {
            if (chatSelection.IsFromGroup) {
                GetGroupDetail(UpdateFromSignar);
            } else {
                GetEmployeesProfile(UpdateFromSignar);
            }
        }
        setIsUpdateName(false);
        if (isFromAppResumeCallBack.current !== undefined && isFromAppResumeCallBack.current === true) {
            setTimeout(() => {
                utils.CallBackGivenToMobile(isIOSMobile ? MobileCallBackAction.HidekeyBord : 29, {})
                isFromAppResumeCallBack.current = false;
            }, 1000);
        }
    }

    useEffect(() => {
        RenderRightSidePanel();
        setShowMoreLocation(false);
        AddCommonMethod("RenderRightSidePanel", () => { debugger; RenderRightSidePanel(true) })
        utils.updateEvent('ClickAddMemberinGroup',()=>{
            if (isAndroidIOSMobile) {
                setAddMembers(true); HandleHistory("push", "EmployeeDropdown"); AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("addMemberCloseBtn"); });
            }else{
                setAddMembers(true)
            }
        })
    }, [chatSelectionID])

    // useEffect(() => {
    //     if (GroupName !== "") {
    //         if (GroupNameFirstRef.current) {
    //             UpdateGroupName();
    //         } else {
    //             GroupNameFirstRef.current = true;
    //         }
    //     }
    // }, [GroupName])

    useEffect(() => {
        if (chatSelection.IsFromGroup === true && addMembers === true && ServiceproviderasperMultilocation.length === 0) {
            const defultValues = (groupDetails?.members || []).map(val => (employeeData || []).find(childval => childval.memberIdHash === val.memberIdHash)).map(val => val?.memberId);
            if (typeof (allserviceprovider) !== undefined && allserviceprovider.length === 0) {
                setServiceproviderasperMultilocation(
                    utils.EmployeeRoleWiseBinding(
                        allserviceprovider,
                        "",
                        props.ReactBusinessId,
                        true,
                        "GroupddEmployee",
                        defultValues
                    )
                )
            } else {
                utils.EmployeeRoleWiseBinding(
                    allserviceprovider,
                    "",
                    props.ReactBusinessId,
                    true,
                    "GroupddEmployee",
                    defultValues
                )
            }

        }
    }, [allserviceprovider, addMembers]);
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.id !== 'groupNameInput' && !event.target.closest('#conformGroupName') && !event.target.closest('#editGroupName')) {
                setIsUpdateName(false)
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => { document.removeEventListener('click', handleOutsideClick); };
    }, []);

    return (
        <div className={isStenciled ? "rightside stenciled" : "rightside"} style={(chatSelectionID === "" || isNewChat) ? { display: "none" } : { display: "block" }} >
            <Containt />
        </div>

    );
}

export const EmployeeDetailPopover = (props) => {
    const { access_token, merchantId, isFromConnectApp, ManageEmployeeViewRights, ManageEmployeeModifyRights, isNewChat, CountryID, chatSelectionID, chatSelectionIDhas , element, convertionData, isAndroidIOSMobile, CommonMethod, setChatAreaShow, setConversetionChatSelection, employeeNewChat, ShowMassage } = props
    const VagaroAPIEnum = {
        GetEmployeeProfile: `${merchantId}/VagaroConnect/employees/detail?EmployeeId=${chatSelectionID}`,
    };
    const [isStenciled, setStenciled] = useState(true);
    const [showMoreLocation, setShowMoreLocation] = useState(false);
    const [employeeProfileDetails, setEmployeeProfileDetails] = useState(EmployeeDetailsData);
    
    const GetEmployeesProfile = async () => {
        setStenciled(true);
        await AsyncAjaxGet(
            VagaroAPIEnum.GetEmployeeProfile,
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        setEmployeeProfileDetails(data.data.data);
                        setStenciled(false);
                    }
                    else {
                        setStenciled(false);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Employee Profile",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    setStenciled(false);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Employee Profile",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                setStenciled(false);
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Employee Profile",
                    "",
                    data
                );
            }
        );
    }
   
    const ActivityStatus = ({ status }) => {
        const Class = () => {
            switch ((status || "").toLowerCase()) {
                case "in appointment":
                    return "active-status status-yellow";
                case "after hours":
                    return "active-status status-gray";
                case "in meeting":
                    return "active-status status-yellow";
                case "on pto":
                    return "active-status status-red";
                case "available":
                    return "active-status status-green";
                default:
                    return "active-status status-red";
            }

        }
        return (<div className="block-status">
            <div className={Class()}>
                {status}
            </div>
        </div>)

    }
    const ChatwithEmployee = (d) => {
        CommonMethods.RemoveElementbyClass('employee-detail-popover')
        var employee = convertionData.filter((val) => (val.customerIdHash === d.memberIdHash && val.isGroupChat === false));
        if (isAndroidIOSMobile) {
            if (employee.length === 0) {
                setTimeout(() => { setChatAreaShow(false) }, 200);
            }
        }
        if (isFromConnectApp && isAndroidIOSMobile) {
            element && element.click();
        }
        if (employee.length > 0) {
            setConversetionChatSelection(employee[0], true)
        } else {
            employeeNewChat(d.memberIdHash || "", true);
        }
    }
    const Containt = () => {
        return (<div className="rightside-wrapper employee-detail" >
            <div className='emp-back-arrow'><i class="fa-solid fa-chevrons-left"></i></div>
            <div className='right-mobileHeaderDetailBlock'>
                <a href={false} id='rightMobileHeaderBack' onClick={() => {
                    element && element.click();
                    if (isAndroidIOSTabIpad()) {
                        var detail = document.getElementById("employee_GroupDetails");
                        CommonMethod.RemoveClass(".vConnectMainWrap.chatActive", "detailsActive");
                        HandleHistory("back");
                        detail && unmountComponentAtNode(detail)
                    }
                }} className='right-mobileHeader-back' >
                    <i class="fa-regular fa-angle-left"></i>
                </a>
                <div className='right-mobileHeader-detail'>
                    Details
                </div>
            </div>
            <div className="personal-info">
                <div className="profile-wrapper text-center services-list-wrap" onClick={() => {
                    if (isFromConnectApp && employeeProfileDetails?.userId) {
                        if (employeeProfileDetails?.employeeInBusiness === false) {
                            return ReactDOM.render(
                                <Fragment>
                                    <SuccessAlert
                                        alertType={"warning"}
                                        strMessage={"You can not edit any details of employee of multilocation business."}
                                        displaytime={3000}
                                    />
                                </Fragment>,
                                document.getElementById("OpenDialog1")
                            );
                        } else {
                            utils.CallBackGivenToMobile(MobileCallBackAction.EmployeeProfile, { "employeeId": employeeProfileDetails.userId })
                        }
                    }
                }}>
                    <div className="profile-image" onClick={() => {
                        if (!isFromConnectApp) {
                            if (employeeProfileDetails?.employeeInBusiness === false) {
                                return ReactDOM.render(
                                    <Fragment>
                                        <SuccessAlert
                                            alertType={"warning"}
                                            strMessage={"You can not edit any details of employee of multilocation business."}
                                            displaytime={3000}
                                        />
                                    </Fragment>,
                                    document.getElementById("OpenDialog1")
                                );
                            } else {
                                window.parent.postMessage('{"eventType": "openEmployeeProfile","Data": "' + employeeProfileDetails.encEmployeeId + '"}', '*');
                            }
                        }
                    }}>
                        <Usericon Mid URL={employeeProfileDetails.cdnUrl} Image={employeeProfileDetails.photo} FirstName={employeeProfileDetails.firstName} LastName={employeeProfileDetails.lastName} />
                    </div>

                    <div className="name-wrap">
                        <h3>{employeeProfileDetails.firstName + " " + employeeProfileDetails.lastName}</h3>
                    </div>
                    <ActivityStatus status={employeeProfileDetails.employeeStatus} />

                </div>

            </div>
            {ShowMassage &&
                <div className='messageBtnwrap'>
                    <button className='btn btn-message' onClick={() => { ChatwithEmployee({ memberIdHash: chatSelectionIDhas }) }}>
                        <span className='messageBtnWrapForMobile'>
                            <i class="fa-regular fa-comment-dots"></i> Message
                        </span>
                    </button>
                </div>
            }
            <div className="bottom-content-wrap">
                <div className="customer-detail-wrap services-list-wrap">
                    <div className="contact-detail">
                        <div className="contact-wrap">
                            {employeeProfileDetails.cell !== undefined && employeeProfileDetails.cell !== null && employeeProfileDetails.cell !== "" &&
                                <a href={false} className={`${(ManageEmployeeViewRights || ManageEmployeeModifyRights) ? 'contact-wrap-callWrap' : 'contact-wrap-callWrap norights'}`} onClick={() => { isFromConnectApp && utils.CallBackGivenToMobile(MobileCallBackAction.PhoneNumber, employeeProfileDetails.cell) }}>
                                    <div className="contact-icon">
                                        <i class="fa-regular fa-phone-flip"></i>
                                    </div>
                                    <span>{(ManageEmployeeViewRights || ManageEmployeeModifyRights) ? employeeProfileDetails.cell : "No Access"}</span>
                                </a>
                            }
                            {employeeProfileDetails.emailId !== undefined && employeeProfileDetails.emailId !== null && employeeProfileDetails.emailId !== "" &&
                                <a href={`mailto: ${employeeProfileDetails.emailId}`} className={`${(ManageEmployeeViewRights || ManageEmployeeModifyRights) ? 'contact-wrap-emailWrap' : 'contact-wrap-emailWrap norights'}`} onClick={() => { isFromConnectApp && utils.CallBackGivenToMobile(employeeProfileDetails.emailId, {}, "Email") }}>
                                    <div className="contact-icon">
                                        <i className="far fa-envelope"></i>
                                    </div>
                                    <span className='contact-wrap-emailText'>{(ManageEmployeeViewRights || ManageEmployeeModifyRights) ? employeeProfileDetails.emailId : "No Access"}</span>
                                </a>
                            }

                        </div>
                    </div>
                </div>
                {employeeProfileDetails?.businessLocations.length > 1 &&
                    <div className="businessLocationWrap">
                        <div>
                            <div className="contact-wrap">
                                <a href={false}>
                                    <div className="contact-icon">
                                        {/*<i class="fa-regular fa-clock"></i>*/}
                                        <i class="far fa-clock"></i>
                                    </div>
                                    <span>{utils.countryDateFormat(moment(), DateFormatType.SmallDateWithTime, CountryID)}</span>
                                </a>
                            </div>

                            <div className="businessLocationBlock">
                                <div className="iconWrap">
                                    <i class="fa-regular fa-store"></i>
                                </div>
                                <div className="locationList">
                                    {(employeeProfileDetails?.businessLocations || []).map((val, index, arr) => {
                                        if (arr.length > 8 && !showMoreLocation) {
                                            if (index === 8) {
                                                return (<><div className="location">{val.businessName}</div> <a href={false} style={{ cursor: "pointer" }} className="locationToogle" onClick={() => setShowMoreLocation(true)}>+ {arr.length - 9 === 1 ? arr.length - 9 + " more location" : arr.length - 9 > 0 && arr.length - 9 + " more locations"}</a></>)
                                            } else if (index < 8) {
                                                return (<div className="location">{val.businessName}</div>)
                                            }
                                        } else {
                                            return (<div className="location">{val.businessName}</div>)
                                        }
                                    }
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

        </div >);
    }

    useEffect(() => {
        GetEmployeesProfile(false);
    }, [chatSelectionID])

    return (
        <div className={isStenciled ? "rightside stenciled employee-detail-popover" : "rightside employee-detail-popover"} style={(chatSelectionID === "" || isNewChat) ? { display: "none" } : { display: "block" }} >
            <Containt />
        </div>

    );
}

