import CustommerDetails from "../CustommerDetails";
import { SelectionControlTypeEnum } from "./ChatBoatEnums";
import React, { Fragment } from "react";

export const ChatbodSelectionData = {
        Type: SelectionControlTypeEnum.None,
        Id: "",
        ConversationId: "",
        Displayname: "",
        Image: "",
        IsFromGroup: false,
        IsBlocked: false,
        AllData: {}
};
export const custommerProfileNoteData = [
        {
                "noteId": "",
                "noteDesc": ""
        },
        {
                "noteId": "",
                "noteDesc": ""
        }
];

export const custommerProfileOutstandigPaymentsData = [
        {
                "incId": "",
                "customerId": "",
                "invoiceNo": "",
                "invoiceTitle": "",
                "dueDate": "",
                "invoicePayUrl": ""
        }
];
export const custommerProfileData = {
        userId: "",
        firstName: "",
        lastName: "",
        fullName: "",
        emailId: "",
        photo: "Images/Avatar.png",
        cdnUrl: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/",
        cell: ""
};
export const UpcomingAppoinmentData = [
        {
                "appointmentID": 0,
                "startTime": "",
                "status": 0,
                "serviceProviderID": 0,
                "serviceID": 0,
                "businessID": 0,
                "businessName": "",
                "serviceTitle": "",
                "serviceProviderFirstName": "",
                "serviceProviderLastName": "",
                "originalPrice": 0.00,
                "resourceName": "",
                "serviceColor": "fff"
        }
]
export const custommerProfilePurcheshData = [
        {
                purchaseId: "0",
                title: "",
                purchaseDate: "",
                phone: "",
                type: ""
        }
]
export const ChatBoatImages = [
        {
                original: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image.png",
                thumbnail: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image.png",
        },
        {
                original: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image1.png",
                thumbnail: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image1.png",
        },
        {
                original: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image2.png",
                thumbnail: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image2.png",
        },
        {
                original: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image3.png",
                thumbnail: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image3.png",
        },
        {
                original: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image4.png",
                thumbnail: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image4.png",
        },
        {
                original: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image5.png",
                thumbnail: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image5.png",
        },
        {
                original: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image6.png",
                thumbnail: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image6.png",
        },
        {
                original: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image7.png",
                thumbnail: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image7.png",
        },
        {
                original: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image8.png",
                thumbnail: "https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/image8.png",
        }
];

export const EmployeeGroupDetailsData = {
        conversationId: "",
        groupName: "",
        members: [
                {
                        memberId: "",
                        fullName: "",
                        cell: "",
                        userType: "0"
                },
                {
                        memberId: "",
                        fullName: "",
                        cell: "",
                        userType: "0"
                }, {
                        memberId: "",
                        fullName: "",
                        cell: "",
                        userType: "0"
                }, {
                        memberId: "",
                        fullName: "",
                        cell: "",
                        userType: "0"
                },
        ]
}
export const EmployeeDetailsData = {
        userId: 0,
        emailId: "",
        cell: 0,
        username: "",
        firstName: "",
        lastName: "",
        userType: "0",
        photo: "174675338_513371_$2024_01_09_07_33_24_0614.jpg",
        cdnUrl: "https://d87d1c6150f9e163834b-81906ee575cd92591782e5779171783e.ssl.cf2.rackcdn.com/",
        fullName: "",
        businessLocations: [
                {
                        "businessId": 0,
                        "businessName": "",
                        "businessURL": ""
                },
                {
                        "businessId": 0,
                        "businessName": "",
                        "businessURL": ""
                },
                {
                        "businessId": 0,
                        "businessName": "",
                        "businessURL": ""
                }
        ]
}
export const textdata = { planUsageLimit: "", usage: 0, activeSMSForMessenger: 0, fromTextMessengerPhone: "", numberStatus: 0, planName: "" }

export const NewDummyData = [
        {
            "conversationId": "",
            "customerIdHash": "",
            "customerId": "",
            "name": "New Chat",
            "lastMessage": "",
            "lastMessageDate": "",
            "isPin": false,
            "isMute": false,
            "userType": 0,
            "isGroupChat": false,
            "messageId": "",
            "isRead": true,
            "profileImage": "",
            "cdnUrl": "",
            "firstName": "",
            "lastName": "",
            "isBlocked": false,
            "pageIndex": 0
        },
    ];

export const StenCiledConvertions = ({ isMobile }) => {
        return (
                <Fragment>
                        {isMobile ?
                                <div>
                                        <div className="messagesArea w-100 stencilBlock-messagesArea">
                                                       
                                                <div className="messagesArea-middle">
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div> 
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>   
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>   
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>   
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>   
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>   
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>   
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>   
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>   
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>      
                                                </div>
                                                {/* <div className="messagesArea-footer">
                                                        <div className="px-4">
                                                                <div className='stencilAnimation border-r10 height-50'></div>
                                                        </div>
                                                </div> */}
                                        </div>
                                </div>
                                :
                                <div>
                                        {/* Stencil Convertion Area For Web */}
                                        <div className="messagesArea w-100 stencilBlock-messagesArea">
                                                {/* <div className="messagesArea-header">
                                                        <div className="stencilBlock-messagesArea_left">
                                                                <div className="stencilAvatar avatar-md"></div>
                                                                <div className="stencilTextline height-20 ml-10 border-r10"></div>
                                                        </div>
                                                        <div className="stencilBlock-messagesArea_right">
                                                                <div className="stencilTextline height-20 width-20 border-r3"></div>
                                                        </div>
                                                </div> */}
                                                <div className="messagesArea-middle">
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                        <div class="recived messagesBox">
                                                                <div className="stencilAvatar avatar-sm"></div>

                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                        </div>
                                                        <div class="sent messagesBox">
                                                                <div class="messagesBox-block">
                                                                        <div class="name stencilTextline height-10 border-r10"></div>
                                                                        <div class="messages stencilTextline"></div>
                                                                        <div class="time stencilTextline height-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilAvatar avatar-sm"></div>
                                                        </div>
                                                </div>
                                                {/* <div className="messagesArea-footer">
                                                        <div className="p-4">
                                                                <div className='stencilAnimation border-r10 height-100'></div>
                                                        </div>
                                                </div> */}
                                        </div>
                                </div>
                        }
                </Fragment>
        )
}
export const StenCiledContaint = ({ isMobile }) => {
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        let isIpadold = /iPad/i.test(navigator.userAgent);
        let isTablet = /VagaroAndroidTablet/i.test(navigator.userAgent);
       
        var View = isMobile !== undefined ? isMobile : (navigator.userAgent.match(/(iPhone|Android)/) && isIpad  === false && isIpadold  === false && isTablet === false)
        return (
                <Fragment>
                        {View ?
                                <div>
                                        <div >
                                                <div class="user-stensil-block">
                                                        <div class="stensil-msg-header">

                                                                <div class="stensil-msg-header-wrap">
                                                                        <div class="stensil-msg-header-text">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>
                                                                </div>
                                                        </div>

                                                        <div class="stensil-navigation">
                                                                <div class="stensil-navigation-link">
                                                                        <div class="stensil-box">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="stensil-navigation-link">
                                                                        <div class="stensil-box">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div class="stensil-search">
                                                                <div class="stensil-search-wrap">
                                                                        <div class="stensil-box sten-height40">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="stensil-search-btnWrap">
                                                                        <div class="stensil-box sten-height40">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>
                                                        <div class="class-row-stensil">
                                                                <div class="clstleft-part">

                                                                        <div class="class-bus-block">
                                                                                <div class="class-bus-inn">
                                                                                        <div class="classbusi-img">
                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                        </div>
                                                                                        <div class="classbusi-detail">
                                                                                                <div class="clbusiness-name">
                                                                                                        <div class="common-livestrnsil-ani"></div>
                                                                                                </div>
                                                                                                <div class="clbusiness-type">
                                                                                                        <div class="halfDiv-type">
                                                                                                                <div class="common-livestrnsil-ani"></div>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div class="clstright-part">
                                                                        <div class="clstprice">
                                                                                <div class="common-livestrnsil-ani"></div>
                                                                        </div>

                                                                </div>
                                                        </div>

                                                </div>

                                        </div>
                                </div>
                                :
                                <div class="stencilChatarea col">
                                        <div class="d-flex">
                                                <div className="stencilChatListBlock">
                                                        <div className="stencilBlock-conversation_search">
                                                                <div className='stencilBlock'>
                                                                        <div className='stencilAnimation height-40 border-r3 mb-10 mr-20'></div>
                                                                        <div className='stencilAnimation height-40 width-60 border-r3 mb-10'></div>
                                                                </div>
                                                        </div>
                                                        <div className="stencilBlock-chatList-header">
                                                                <div className='stencilBlock tabBlock'>
                                                                        <div className='stencilAnimation height-20 w-50 border-r3 mb-10 mr-10'></div>
                                                                        <div className='stencilAnimation height-20 w-50 border-r3 mb-10'></div>
                                                                </div>
                                                        </div>
                                                        <div className="stencilBlock-chatListBlock">
                                                                <div className="stencilBlock-chatListBlock-header">
                                                                        <div className='stencilBlock'>
                                                                                <div className='stencilAnimation'></div>
                                                                        </div>
                                                                </div>

                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div><div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="stencilBlock-chatListBlock-personBlock">
                                                                        <div className="stencilBlock-chatListBlock-person">
                                                                                <div className='stencilBlock-chatListBlock-person_avatar'>
                                                                                        <div className='stencilAnimation'></div>
                                                                                </div>
                                                                                <div className='stencilBlock-chatListBlock-person_info'>
                                                                                        <div className='stencilAnimation textLine mb-2'></div>
                                                                                        <div className='stencilAnimation textLine'></div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                                <div className="messagesArea w-100 stencilBlock-messagesArea">
                                                        <div className="messagesArea-header">
                                                                <div className="stencilBlock-messagesArea_left">
                                                                        <div className="stencilAvatar avatar-md"></div>
                                                                        <div className="stencilTextline height-20 ml-10 border-r10"></div>
                                                                </div>
                                                                <div className="stencilBlock-messagesArea_right">
                                                                        <div className="stencilTextline height-20 width-20 border-r3"></div>
                                                                </div>
                                                        </div>
                                                        <div className="messagesArea-middle">
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>
                                                                <div class="recived messagesBox">
                                                                        <div className="stencilAvatar avatar-sm"></div>

                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                </div>
                                                                <div class="sent messagesBox">
                                                                        <div class="messagesBox-block">
                                                                                <div class="name stencilTextline height-10 border-r10"></div>
                                                                                <div class="messages stencilTextline"></div>
                                                                                <div class="time stencilTextline height-10 border-r10"></div>
                                                                        </div>
                                                                        <div className="stencilAvatar avatar-sm"></div>
                                                                </div>

                                                        </div>
                                                        <div className="messagesArea-footer">
                                                                <div className="p-4">
                                                                        <div className='stencilAnimation border-r10 height-100'></div>
                                                                </div>
                                                        </div>
                                                </div>
                                                <div className="rightSlide stencilBlock-rightSlide text-center">
                                                        <div className="proData">
                                                                <div className="stencilAvatar avatar-md"></div>
                                                                <div className="stencilTextline height-10 width-100 border-r10 m-0 mb-5"></div>
                                                                <div className="stencilTextline height-10 width-60 border-r10 m-0"></div>
                                                        </div>
                                                        <div className="proDataEmail">
                                                                <div className="stencilTextline height-10 border-r10 m-0 mb-5"></div>
                                                                <div className="stencilTextline height-10 border-r10 m-0"></div>
                                                        </div>

                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>
                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-70 border-r10"></div>
                                                        </div>

                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-40 border-r10"></div>
                                                        </div>

                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-40 border-r10"></div>
                                                        </div>

                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-40 border-r10"></div>
                                                        </div>

                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-40 border-r10"></div>
                                                        </div>

                                                        <div className="sreviceCard">
                                                                <div className="stencilTextline height-10 border-r10 mb-10"></div>
                                                                <div className="stencilTextline height-40 border-r10"></div>
                                                        </div>

                                                </div>

                                        </div>
                                </div>
                        }
                </Fragment>
        )
}
